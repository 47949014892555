ion-radio {
    --color-checked: var(--color-blue-300);
    --color: var(--color-blue-300);
}

.radio-custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    ion-label {
        margin-left: 18px;
    }
}

ion-radio {
    --color: var(--color-neutral-100);
    --color-checked: var(--color-blue-300);

    &.ios::part(container) {
        border: 1px solid var(--color-neutral-100);
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
}

.radio-checked {
    &.ios::part(container) {
        border-color: var(--color-blue-300);
    }
}

.filter-radio {
    margin-right: 2em;
}

.select-interface-option.md.alert-radio-button {
    .alert-radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        .alert-radio-inner {
            position: relative;
            top: initial;
            inset-inline-start: initial;
        }
    }
}


.add-type-radio-item {
    --border-width:2px;
    ::part(container) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
