.ion-modal {
    --backdrop-opacity: .7;
    --border-radius: 12px;
    --background: #F9F9F9;
    --ion-toolbar-background: var(--background);
    --ion-item-background: transparent;
    --ion-padding: 24px;
    --max-width: 540px;
    --ion-text-color: var(--color-neutral-900);
    --box-shadow: none;
    --border-width: 0;

    @media only screen and (max-width: 768px) {
        --max-height: 95%;
        --border-radius: 26px 26px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    ion-grid {
        --ion-grid-padding: 0;
        --ion-grid-column-padding: 10px;
    }

    ion-content {
        --background: var(--background);
        --padding-start: 30px;
        --padding-end: 30px;
        --padding-top: 40px;
        --padding-bottom: 30px;

        .input-item {
            .sc-ion-input-ios-h,
            .sc-ion-input-md-h,
            ion-select,
            ion-textarea {
                --background: var(--color-neutral-100) !important;
                --border-radius: 10px;
                --border-color: transaprent;
                --highlight-color-focused: --border-color;
                --highlight-color-invalid: --border-color;
                --highlight-color-valid: --border-color;
                --color: var(--color-violet-400);
                --placeholder-color: var(--color-neutral-300);
                --placeholder-opacity: .6;
            }
        }
    }

    ion-toolbar {
        --padding-start: 30px;
        --padding-end: 22px;
        --padding-bottom: 0;
        --padding-top: 24px;
        --border-width: 0 !important;

        ion-buton, ion-icon {
            cursor: pointer;
        }

        ion-icon {
            color: var(--color-neutral-300);
        }
    }

    ion-header {
        box-shadow: none;
    }

    ion-footer {
        ion-toolbar {
            --padding-start: 30px;
            --padding-end: 30px;
            --padding-bottom: 30px;
            --padding-top: 25px;
            --border-width: 0 !important;
        }

        box-shadow: none;
    }

    .modal-title {
        text-align: left;
        font-size: 26px;
        font-weight: 800;
        margin-top: 10px;
        color: var(--color-blue-300);
        padding: 0 20px 0 0;
    }

    .modal-title-descr {
        text-align: center;
        margin-bottom: 38px;
        display: block;
        color: var(--color-neutral-600);
    }

    &.ion-modal-shadow {
        --box-shadow: 0 0 15px 0px rgba(0, 0, 0, .2) !important;
    }

    .btn-group {
        flex-direction: row;

        ion-button {
            margin: 0;
        }
    }

    ion-button {
        --height: 40px;
        --padding-start: 22px;
    }
}

.ion-modal-side-right {
    --height: 100%;
    --border-radius: 26px 26px 0 0;
    justify-content: flex-end !important;
    @media screen and (min-width: 768px) {
        --border-radius: 26px 0 0 26px;
    }
}


