/* Set the width to the full container and center the content */
ion-select {
    --background: var(--default-white);
    --border-radius: 10px;
    --border-color: transparent;
    --highlight-color-focused: --border-color;
    --highlight-color-invalid: --border-color;
    --highlight-color-valid: --border-color;
    --border-width: 0;

    --font-size: 16px;
    --font-weight: 600;
    --line-height: 1.5;
    --text-align: left;

    --padding-bottom: 14px;
    --padding-top: 14px;
    --padding-end: 18px;
    --padding-start: 18px;
    --height: 50px;

    border-radius: var(--radius);
    --color: var(--color-blue-400);
    --placeholder-color: var(--color-neutral-200);
    --placeholder-opacity: 0.5;
    color: var(--color);
    min-height: var(--height);
    font-size: var(--font-size);
}

/* Set the placeholder color and opacity */
ion-select::part(placeholder) {
    color: var(--placeholder-color);
    //opacity: 1;
}

s
    /* Set the text color */
ion-select::part(text) {
    color: var(--color);
}

.required {
    ion-select::part(label) {
        &:after {
            color: #e32;
            content: ' *';
            display: inline;
        }
    }
}

ion-select::part(label) {
    color: var(--color-neutral-300);
}

.select-no-label {
    &::part(label) {
        display: none;
    }
}


/* Set the icon color and opacity */
ion-select::part(icon) {
    color: var(--color);
    opacity: 1;
}
