.content-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    padding: 0;

    --items-margin: 0 16px 0 0 0;

    .add_to_board {
        margin-top: 15px;
        margin-left: 0;
    }

    @media screen and (min-width: 768px) {
        --items-margin: 0 16px 0 0;
        .content-title {
            margin: var(--items-margin);
        }

        .add_to_board {
            margin-left: auto;
            margin-top: 0;
        }
    }
}

.tabs {
    --tab-height: 54px;
    //--tab-padding: 34px 0;
    //--tab-bottom-margin: 12px;
    --tab-scroll-vertical-padding: 36px;
    --tab-scroll-horizontal-padding: 20px;

    padding: var(--tab-padding);
    position: relative !important;

    ion-tab-bar {
        height: var(--tab-height);
        margin-bottom: var(--tab-bottom-margin);
        align-items: normal;
        justify-content: normal;
        border: none;
    }
}

.tab-scroll-container {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    padding-bottom: var(--tab-scroll-horizontal-padding);

    @media screen and (min-width: 768px) {
        &:before, &:after {
            content: '';
            min-width: var(--tab-scroll-vertical-padding);
            display: block;
        }
    }

    .tab-button {
        flex-shrink: 0;
        border-radius: 10px;
        --background: var(--color-neutral-100);
        --background-focused: var(--color-neutral-300);
        --background-focused-opacity: 5;
        --color: var(--color-neutral-780);
        --color-focused: var(--color-neutral-780);
        --color-selected: var(--color-neutral-780);
        --padding-end: 7px;
        --padding-start: 17px;
        --padding-top: 10px;
        --padding-bottom: 10px;
        min-width: 160px;
        height: 34px;
        margin-right: 8px;

        &.tab-selected {
            --background: var(--color-blue-300);
            --background-focused: var(--color-blue-300);
            --background-focused-opacity: 5;
            --color: var(--default-white);
            --color-focused: var(--default-white);
            --color-selected: var(--default-white);

            .tab-button--button {
                --color: var(--default-white);
            }
        }
    }

    .tab-add-button-container {
        position: sticky;
        right: 0;
        top: 0;
        z-index: 1;

        &:before {
            content: '';
            background: #F9F9F9;
            position: absolute;
            border-radius: 5px 0 0 5px;
            width: 200px;
            left: -5px;
            top: 0;
            height: 34px;
        }

        .tab-button {
            --background: var(--color-green-300);
            --background-focused: var(--color-green-300);
            --background-focused-opacity: 5;
            --color: var(--default-white);
            --color-focused: var(--default-white);
            --color-selected: var(--default-white);
            max-width: calc(var(--tab-height) - var(--tab-scroll-horizontal-padding));
            min-width: calc(var(--tab-height) - var(--tab-scroll-horizontal-padding));
            flex-shrink: 0;
            --padding-end: 0;
            --padding-start: 0;
            --padding-top: 0;
            --padding-bottom: 0;
            position: relative;
            display: inline-block;
        }
    }

    .button-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .tab-icon {
            font-size: 20px;
            margin-right: 8px;
            flex-shrink: 0;
        }

        .tab-title {
            font-size: 12px;
            font-weight: 700;
            line-height: 1.3;
            color: inherit;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .tab-button--button {
        --height: 10px;
        --background: transparent;
        --background-activated: transparent;
        --background-focused: transparent;
        --color: var(--color-neutral-500);
        --padding-bottom: 0;
        --padding-end: 0;
        --padding-start: 0;
        --padding-top: 0;
        --border-radius: 50%;
        min-height: 28px;
        margin: 0 0 0 auto;
        padding: 0;
        width: 28px;

        .tab-icon {
            margin: 0;
            padding: 0;
        }
    }

}

.tab-content {
    padding: 20px;
    height: calc(100vh - 160px);
}

ion-chip {
    ion-icon {
        margin: 0;
    }
}

.report-table {
    --container-padding: 15px;
    --cell-padding-y: 9px;
    --cell-padding-x: 10px;
    --sticky-col-width: 175px;
    background: var(--default-white);
    padding: var(--container-padding);
    border-radius: 12px;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
        --container-padding: 20px;
        --cell-padding-y: 22px;
        --cell-padding-x: 15px;
        ---sticky-col-width: 230px;
    }

    .wrapper {
        position: relative;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 15px;
    }

    .table {
        width: 100%;

        th {
            font-size: 10px;
            color: var(--color-neutral-800);
            line-height: 1.4;
            padding: 10px var(--cell-padding-x);
        }

        th, td {
            text-align: left;
            min-width: 100px;
        }

        td {
            font-size: 12px;
            line-height: 1.3;
            color: var(--color-neutral-300);
            font-weight: 500;
            padding: var(--cell-padding-y) var(--cell-padding-x);
        }

        tr {
            td, th, .first-col {
                background: var(--color-shades-100);
                border-bottom: 1px solid var(--color-neutral-50);
            }

            td.first-col {
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--color-shades-100);
                    border-bottom: 1px solid var(--color-neutral-50);
                    z-index: -1;
                }
            }

            &:last-child {
                td {
                    border: none;

                    &.first-col {
                        &:before {
                            border-bottom: none;
                        }
                    }
                }
            }

            &.odd {
                td {
                    &.first-col {
                        background: rgba(245, 245, 245, .7);

                        &::before {
                            background: rgba(245, 245, 245, .7);
                        }
                    }

                    background: rgba(245, 245, 245, .7);
                }
            }
        }
    }

    .sticky-col {
        position: sticky;
    }

    .first-col {
        left: 0;
        color: var(--color-neutral-800) !important;
        font-weight: 700 !important;
        z-index: 1;
        min-width: var(--sticky-col-width, 200px) !important;
        max-width: var(--sticky-col-width, 200px) !important;
        padding-left: 0 !important;
    }

}

.widget-grid {
    background: transparent;
    position: relative !important;

    gridster-item {
        background: transparent;
        border-radius: 12px;

        &.gridster-item-moving {

        }

        .gridster-item-resizable-handler.handle-se {
            border-width: 0 0 46px 46px;
            z-index: 9999;
            transition: all .3s ease;
        }

        &:hover {
            .gridster-item-resizable-handler.handle-se {
                border-color: transparent transparent rgba(217, 217, 217, .5);
            }
        }
    }

    .gridster-row {
        border-top: 1px dashed var(--color-neutral-100);
        border-bottom: 1px dashed var(--color-neutral-100);
    }

    .gridster-column {
        border-left: 1px dashed var(--color-neutral-100);
        border-right: 1px dashed var(--color-neutral-100);
    }

    .gridster-preview {
        border-radius: 12px;
        background: rgba(217, 217, 217, .3);
    }
}
