ion-checkbox {
    --size: 20px;
    //--border-width: 1px;
    //--padding-end: 10px;
    //--checkbox-background: var(--default-white);
    //--checkbox-background-checked: var(--default-white);
    //--checkmark-color: var(--color-green-500);
    //--checkmark-width: 2px;
    //--border-color: var(--color-neutral-600);
    //--border-color-checked: var(--color-green-500);
    //--border-radius: 3px;
    //margin-inline-end: 15px;
}
