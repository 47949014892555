ion-popover {
    --min-width: 280px;
    --width: 320px;
    --max-width: 600px;
    --max-height:80vh;


}

.select-popover {
    ion-item {
        --padding-start: 10px;
        --inner-padding-end: 10px;
    }
}
