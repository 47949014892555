
ion-alert {
    &.select-alert {
        --min-width: 280px;
        --width: 320px;
        --max-width: 600px;
    }
}

.select-all-options-alert {

    .alert-button-group {
        flex-direction: row;
        flex-wrap: wrap;

        .alert-button {
            flex-basis: 50%;
            flex-shrink: 0;
            flex-grow: 0;
            width: 50%;
            min-width: 0;
            --min-width: 50%;
            margin: 0 !important;

            .alert-button-inner {
                justify-content: center;
                text-align: center;
            }
        }
    }
}
