
ion-textarea {
    --background: var(--default-white) !important;
    --color: var(--color-violet-400) !important;
    --placeholder-color: var(--color-neutral-200) !important;
    --placeholder-opacity: 0.5 !important;
    --min-height: 150px;

    --border-radius: 12px !important;
    --box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.15);

    --font-size: 16px;
    --font-weight: 400;
    --line-height: 1.5;
    --text-align: left;

    --padding-bottom: 14px !important;
    --padding-top: 14px !important;
    --padding-end: 18px !important;
    --padding-start: 18px !important;

    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    font-weight: var(--font-weight);
    font-size: var(--font-size) !important;
    line-height: var(--line-height);
    text-align: var(--text-align);
    margin-top: 0 !important;
    min-height: var(--min-height) !important;

    textarea {
        --tw-ring-color: none !important; //override tailwind color for input
    }

    .textarea-highlight {
        //background: transparent !important;
        margin-left: 10px !important;
        height: 1px !important;
        width: calc(100% - 20px) !important;
        bottom: 0 !important;
    }
}
