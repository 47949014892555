.import-content-modal {
    .mega-picker {
        position: relative;
        overflow: hidden;
        --wheel-fade-background-rgb: transparent;
        --wheel-highlight-background: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;

        .legend-container {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: nowrap;
        }

        .legend {
            font-size: 10px;
            line-height: 50px;
            color: var(--color-neutral-600);
            width: 100px;
            text-align: center;
        }

        .time-picker-picker {
            position: relative;
            overflow: hidden;
        }

        .dots {
            position: absolute;
            pointer-events: none;
            width: 16px;
            top: -41px;
            left: 50%;
            transform: translateX(-9px);
            text-align: center;

            .dot {
                color: var(--color-neutral-600);
                font-weight: 200;

                &:after {
                    content: ':';
                    font-size: 52px;
                    line-height: 1;
                    height: 55px;
                    display: inline-block;


                }

                &:nth-child(3) {
                    font-weight: 300;
                    color: var(--color-green-300);
                }
            }
        }

        ion-datetime {
            overflow: hidden;

            &::part(wheel-item) {
                font-size: 52px;
                line-height: 34px;
                height: 40px;
                letter-spacing: 1.04;
                margin: 15px 0;
                color: var(--color-neutral-800);
                font-weight: 200;
                text-align: center;
                position: relative;
                transition: all .3s ease;
                overflow: initial;
            }

            &::part(wheel-item active) {
                color: var(--color-green-300);
                //font-weight: 300;
            }

        }
    }

    .minmaxpicker {
        &::part(calendar-day today) {
            color: var(--default-white);
        }

        &::part(calendar-day):hover {
            background-color: rgb(154 209 98 / 0.2);
            box-shadow: 0px 0px 0px 4px rgb(154 209 98 / 0.2);
        }

        &::part(calendar-day active):hover {
            background: var(--color-green-300);
            color: var(--default-white);
        }
    }
}
