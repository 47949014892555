ion-button {
    --background: var(--color-green-300);
    --background-activated: var(--color-green-500);
    --background-focused: var(--color-green-400);
    --color: var(--color-blue-500);
    --border-radius: 12px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 75px;
    --padding-end: var(--padding-start);
    --height: 50px;
    --font-weight: 700;
    --font-size: 16px;
    --line-height: 1.1875;
    --box-shadow: none;
    cursor: pointer;

    font-weight: var(--font-weight);
    text-transform: none;
    font-size: var(--font-size);
    line-height: var(--line-height);
    height: var(--height);

    @media only screen and (max-width: 768px) {
        --height: 44px;
    }

    &.button-disabled {
        --ion-color-base: var(--color-neutral-200) !important;
        --background: var(--color-neutral-200) !important;
        --color: var(--color-neutral-300) !important;
        --ion-color-contrast: var(--color-neutral-300) !important;
        --opacity: 1;
        opacity: 1;
    }

    &.button-small {
        --padding-top: 6px;
        --padding-bottom: 6px;
        --padding-start: 12px;
        --height: 32px;
        --font-weight: 600;
        --font-size: 13px;
    }

    &.button-default {
        --padding-start: 50px;
    }

    &.button-has-icon-only {
        --padding-start: var(--padding-top);
    }

    &.button-clear {
        --padding-top: 5px;
        --padding-bottom: 5px;
        --height: auto;
    }

    ion-icon {
        flex-shrink: 0;
        font-size: 28px;
    }
}

.icon-only-button {
    cursor: pointer;
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ion-button {
        margin: 0 0 20px 0;
    }

    .cancel-btn {
        margin-top: 4px;
    }
}

