.minmaxpicker {
    ::part(calendar-day today) {
        color: #8462d1;
    }

    ::part(calendar-day):hover {
        background-color: rgb(154 209 98 / 0.2);
        box-shadow: 0px 0px 0px 4px rgb(154 209 98 / 0.2);
    }

    ::part(calendar-day active):hover {
        background: var(--color-blue-300);
        color: var(--default-white);
    }
}

.time-select--modal {
    --max-width: 320px;
    --backdrop-opacity: .3;
    --border-radius: 26px;
    --background: var(--default-white);
    --ion-toolbar-background: var(--default-white);;
    --ion-item-background: transparent;
    --ion-padding: 24px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;


    .modal--theme {
        padding: 28px 10px;
    }

    ion-toolbar {
        --padding-start: 24px;
        --padding-end: 24px;
        --padding-bottom: 24px;
        --padding-top: 24px;
        --border-width: 0 !important;
    }

    ion-header {
        ion-toolbar {
            --padding-bottom: 0;
        }
    }

    .button {
        width: 50%;
        display: flex;
        margin: 20px auto;
        min-width: 150px;
    }

    .bottom-holder {
        padding: 20px;
    }
}

.datetime-presentation-date {
    &::part(calendar-day) {
        width: 36px !important;
        min-width: 36px !important;
        height: 36px !important;
        padding-inline: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-inline: 3px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    &::part(calendar-day active) {
        color: var(--default-white) !important;
    }

    &.ios {
        &::part(calendar-day active) {
            color: var(--color-neutral-900) !important;
            background: var(--color-green-300) !important;
        }
    }
}
