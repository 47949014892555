.wrong-widget-setup {
    text-align: center;
    margin: auto;
    display: flex;
    grid-row: 1/5;
    padding: 20px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--color-highlight-300);
}

.widget-group-calculation-type {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-blue-300);
}
