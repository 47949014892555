.sc-ion-input-ios-h,
.sc-ion-input-md-h {
    --background: var(--default-white);
    --border-radius: 10px;
    --border-color: transaprent;
    --highlight-color-focused: --border-color;
    --highlight-color-invalid: var(--color-highlight-300);
    --highlight-color-valid: var(--color-green-400);
    --color: var(--color-violet-400);
    --placeholder-color: var(--color-neutral-200);
    --placeholder-opacity: 0.5;
    --padding-end: 18px;
    --padding-start: 18px;
    --height: 50px;
    min-height: 50px !important;
    font-size: 16px;

    .input-bottom {
        --highlight-color-invalid: var(--ion-color-danger) !important;
    }

    &.ion-invalid.ion-dirty {
        --highlight-color-focused: var(--color-highlight-300);
    }


    .helper-text,
    .counter {
        color: var(--ion-color-step-700, #373737);
    }

    .input-highlight {
        //background: transparent !important;
        margin-left: 10px;
        height: 1px !important;
        width: calc(100% - 20px);
        bottom: 0 !important;
    }

    &.input-disabled {
        opacity: .4;
        --color: var(--color-blue-400);
    }
}

.sc-ion-input-ios-h {
    border-bottom: 1px solid transparent;
    border-radius: var(--border-radius);

    &.ion-invalid.ion-dirty {
        border-bottom: 1px solid var(--color-highlight-300);
    }
}

.input-item {
    --margin-bottom: 15px;
    --margin-last-bottom: 0;
    margin-bottom: var(--margin-bottom);
    position: relative;

    .input-item-title {
        color: var(--color-blue-500);
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 4px;
        min-height: 28px;
        display: block;
    }

    &:last-child {
        margin-bottom: var(--margin-last-bottom);
    }

    ion-note {
        margin-bottom: 3px;
        font-size: 12px;

        &:first-child {
            margin-top: 5px;
        }
    }

    &.has-button {
        display: flex;
        flex-direction: row;
        align-items: center;

        .input-button {
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: var(--padding-start);
        }
    }

    .label-text {
        color: var(--color-neutral-300);
    }

    &.required {
        .label-text {
            &:after {
                color: #e32;
                content: ' *';
                display: inline;
            }
        }
    }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
