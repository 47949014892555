.view-switch {
    --background: rgba(217, 217, 217, .5);
    border-radius: 4px;
    overflow: hidden;
    width: 70px;


    ion-segment-button {
        --background: transparent;
        --background-checked: var(--color-blue-400);
        --background-hover: var(--color-blue-100);
        --background-hover-opacity: .1;
        --background-focused: none;
        --background-focused-opacity: 0;
        --border-radius: inital;
        --border-width: 0;
        --border-color: transparent;
        --indicator-box-shadow: initial;
        --indicator-color: transparent;
        --padding-top: 0;
        --padding-end: 0px;
        --padding-bottom: 0;
        --padding-start: 0px;
        --color: var(--color-violet-100);
        --color-checked: var(--default-white);
        --indicator-height: 0;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        flex-direction: row;
        min-width: 35px;
        min-height: 40px;
        line-height: 1;
        //@media (prefers-color-scheme: dark) {
        //    --background-checked: var(--color-green-300);
        //    --background-hover: var(--color-green-400);
        //}
    }

    ion-icon {
        font-size: 20px;
        margin: 0;
    }
    //@media (prefers-color-scheme: dark) {
    //    --background: var(--color-neutral-50);
    //    ion-segment-button {
    //        --background-checked: var(--color-green-300);
    //        --background-hover: var(--color-green-400);
    //    }
    //}
}
