
.board-content {
    @media screen and (min-width: 768px) {
        padding: 10px 30px 40px 30px;
    }

    .content-title {
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        color: var(--color-blue-300);
        margin: 0 20px 0 0;
    }

    &.tabs-page {
        @media screen and (min-width: 768px) {
            padding: 0px 30px 0 30px;
        }
    }
}

.content-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    order: 1;
    --items-margin: 16px 16px 0 0;

    .input-item {
        margin: var(--items-margin);
        order: 3;
    }

    .add_to_board {
        order: 2;
    }

    .title-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        .content-title, .team-title {
            --items-margin: 0 16px 0 0;
        }
    }

    @media screen and (min-width: 768px) {
        --items-margin: 0 16px 0 0;
        .content-title {
            margin: var(--items-margin);
        }

        .input-item {
            margin-left: auto;
            order: 2;
        }
        .add_to_board {
            order: 3;
        }
    }
}
