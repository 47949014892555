.sc-ion-searchbar-md-h, .sc-ion-searchbar-ios-h {
    --background: rgba(217, 217, 217, .5);
    --border-radius: 4px;
    --box-shadow: none;
    --cancel-button-color: var(--color-violet-100);
    --clear-button-color: var(--color-violet-100);
    --color: var(--color-violet-100);
    --icon-color: var(--color-violet-100);
    height: 30px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    font-weight: 600;

    .searchbar-input-container.sc-ion-searchbar-ios {
        height: 30px;
    }
    .searchbar-input.sc-ion-searchbar-md{
        padding-inline-start: 42px;
        padding-inline-end: 42px;
    }
    //@media (prefers-color-scheme: dark) {
    //    --background: var(--color-neutral-50);
    //}
}
