@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-Bold.eot');
    src: url('../assets/fonts/static/inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-Bold.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-Bold.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-Bold.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-Bold.svg#Inter-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-SemiBold.eot');
    src: url('../assets/fonts/static/inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-SemiBold.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-SemiBold.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-Medium.eot');
    src: url('../assets/fonts/static/inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-Medium.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-Medium.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-Medium.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-Medium.svg#Inter-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-ExtraBold.eot');
    src: url('../assets/fonts/static/inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-ExtraBold.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-ExtraBold.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-ExtraLight.eot');
    src: url('../assets/fonts/static/inter/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-ExtraLight.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-ExtraLight.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-ExtraLight.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-ExtraLight.svg#Inter-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-Regular.eot');
    src: url('../assets/fonts/static/inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-Regular.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-Regular.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-Regular.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-Light.eot');
    src: url('../assets/fonts/static/inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-Light.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-Light.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-Light.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-Light.svg#Inter-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/static/inter/Inter-Light.eot');
    src: url('../assets/fonts/static/inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/inter/Inter-Light.woff2') format('woff2'),
    url('../assets/fonts/static/inter/Inter-Light.woff') format('woff'),
    url('../assets/fonts/static/inter/Inter-Light.ttf') format('truetype'),
    url('../assets/fonts/static/inter/Inter-Light.svg#Inter-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

